import React, { useState, useRef } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const ContactPage = () => {
	const [showDates, setShowDates] = useState(true)
	const formEl = useRef<HTMLFormElement>(null)

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		if (!formEl.current) {
			return
		}

		if (formEl.current.checkValidity() === false) {
			e.preventDefault()
			e.stopPropagation()
		}

		formEl.current.classList.add('was-validated')
	}

	return (
		<Layout>
			<SEO title="Order now" />
			<div className="container marketing mt-0 pt-md-5">
				<h1 className="display-4 mb-5 text-center">Order the Card</h1>
				<form
					className="needs-validation"
					noValidate
					ref={formEl}
					onSubmit={handleSubmit}
					action="https://www.grcm.cz/api/pgc/"
					method="POST"
				>
					<input
						type="hidden"
						name="ReturnUrl"
						value="https://igtm.praguegolfcard.cz/submitted/"
					/>

					<div className="row">
						<div className="col-md-6 mb-4">
							<label htmlFor="FirstName">First name</label>
							<input
								type="text"
								className="form-control"
								id="FirstName"
								name="FirstName"
								required
							/>
							<div className="invalid-feedback">
								Valid first name is required.
							</div>
						</div>
						<div className="col-md-6 mb-4">
							<label htmlFor="LastName">Last name</label>
							<input
								type="text"
								className="form-control"
								id="LastName"
								name="LastName"
								required
							/>
							<div className="invalid-feedback">
								Valid last name is required.
							</div>
						</div>
					</div>
					<div className="mb-3">
						<label htmlFor="TourOperator">
							Tour Operator <span className="text-muted">(Optional)</span>
						</label>
						<input type="text" className="form-control" id="TourOperator" />
					</div>
					<div className="mb-3">
						<label htmlFor="Email">Contact e-mail</label>
						<input
							type="email"
							className="form-control"
							id="Email"
							name="Email"
							required
						/>
						<div className="invalid-feedback">
							Please enter a valid email address.
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 mb-4">
							<label htmlFor="DateFrom">Date of arrival</label>
							<input
								type="date"
								className="form-control"
								id="DateFrom"
								name="DateFrom"
								required
								disabled={!showDates}
							/>
							<div className="invalid-feedback">
								Date of arrival is required.
							</div>
						</div>
						<div className="col-md-6 mb-4">
							<label htmlFor="DateTo">Date of departure</label>
							<input
								type="date"
								className="form-control"
								id="DateTo"
								name="DateTo"
								required
								disabled={!showDates}
							/>
							<div className="invalid-feedback">
								Date of departure is required.
							</div>
						</div>
					</div>
					<div className="custom-control custom-checkbox mb-4">
						<input
							type="checkbox"
							className="custom-control-input"
							id="NoDates"
							name="NoDates"
							value="true"
							onChange={e => setShowDates(!e.target.checked)}
						/>
						<label className="custom-control-label" htmlFor="NoDates">
							I do not know exact dates of arrival/departure yet
						</label>
					</div>
					<hr className="mb-4" />
					<div className="row">
						<div className="col-md-4 mb-4">
							<label htmlFor="GolfCourse1">
								1st day of play (preferred golf course)
							</label>
							<select
								className="custom-select d-block w-100"
								id="GolfCourse1"
								name="GolfCourse1"
							>
								<option value="">Choose...</option>
								<option>Golf Resort Black Bridge</option>
								<option>Prague City Golf Zbraslav</option>
								<option>Loreta Golf Club Pyšely</option>
								<option>Golf Club Mstětice</option>
								<option>Golf Beřovice</option>
							</select>
						</div>
						<div className="col-md-4 mb-4">
							<label htmlFor="GolfCourse2">
								2nd day of play (preferred golf course)
							</label>
							<select
								className="custom-select d-block w-100"
								id="GolfCourse2"
								name="GolfCourse2"
							>
								<option value="">Choose...</option>
								<option>Golf Resort Black Bridge</option>
								<option>Prague City Golf Zbraslav</option>
								<option>Loreta Golf Club Pyšely</option>
								<option>Golf Club Mstětice</option>
								<option>Golf Beřovice</option>
							</select>
						</div>
						<div className="col-md-4 mb-4">
							<label htmlFor="GolfCourse3">
								3rd day of play (preferred golf course)
							</label>
							<select
								className="custom-select d-block w-100"
								id="GolfCourse3"
								name="GolfCourse3"
							>
								<option value="">Choose...</option>
								<option>Golf Resort Black Bridge</option>
								<option>Prague City Golf Zbraslav</option>
								<option>Loreta Golf Club Pyšely</option>
								<option>Golf Club Mstětice</option>
								<option>Golf Beřovice</option>
							</select>
						</div>
					</div>
					<div className="custom-control custom-checkbox mb-4">
						<input
							type="checkbox"
							className="custom-control-input"
							id="SetUpTournaments"
							name="SetUpTournaments"
							value="true"
						/>
						<label className="custom-control-label" htmlFor="SetUpTournaments">
							I would like to set up tournaments (+60 € per person)
						</label>
					</div>
					<hr className="mb-4" />
					<div className="mb-3">
						<label htmlFor="AdditionalWishes">
							Would you like play more rounds or do you have special request?
							Tell us.
						</label>
						<textarea
							className="form-control"
							id="AdditionalWishes"
							name="AdditionalWishes"
							rows={3}
						/>
					</div>
					<hr className="mb-4" />
					<div className="custom-control custom-checkbox mb-4">
						<input
							type="checkbox"
							className="custom-control-input"
							id="GDPR"
							required
						/>
						<label className="custom-control-label" htmlFor="GDPR">
							I consent to the processing of my data in accordance with General
							Data Protection Regulation
						</label>
						<div className="invalid-feedback">
							You must consent before submitting.
						</div>
					</div>
					<hr className="mb-4" />
					<div className="text-center">
						<button
							className="btn btn-success btn-lg btn-block mx-auto w-75 contact-submit"
							type="submit"
						>
							Send order
						</button>
						<p className="mt-2">
							or write us an e-mail at{' '}
							<a href="mailto:info@praguegolfcard.cz">info@praguegolfcard.cz</a>
						</p>
					</div>
				</form>
			</div>

			<hr className="featurette-divider" />
		</Layout>
	)
}

export default ContactPage
